<template>
  <div class="about bg-black text-white text-7xl p-2 m-2">
      <i class="fas fa-hospital-user"></i>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
//import {LoginSVG} from "./../assets/svgs/LoginSVG.vue"

export default defineComponent({
  components:{},
  setup() {
    return {}
  },
})
</script>
